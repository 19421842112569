.progressOuterContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 120px;
  padding-left: 20px;
  padding-right: 20px;
}
.progressContainer {
  height: 200px;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.plansContainer {
  display: flex;
  justtify-content: center;
  align-items: center;
  margin-top: 60px;
  paddingleft: 40px;
  padding-bottom: 60px;
}

.planAlreadySelected {
  display: flex;
  justify-content: center;
  flex: 1;
  padding-bottom: 60px;
  font-weight: 600;
  font-size: 20px;
}
